
<main style="background-color: white;">
    <div style="padding-left: 10%;margin-bottom: 5dvh;padding-top: 10%;">
        <h2><b>OTHER INQUIRIES</b></h2>
    </div>
    <div style="padding-left: 10%;">
        <h3><b>INQUIRY FORM</b></h3>
        <form [formGroup]="firstFormGroup" (ngSubmit)="submit()">
            <div class="row mt-4">
                <div class="col-4">
                    <mat-label class="mb-2"><b>First Name *</b></mat-label>
                <mat-form-field appearance="outline" class="mt-2">
                    <input matInput placeholder="Enter name" formControlName="first_name" />
                </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-label class="mb-2"><b>Last Name *</b></mat-label>
                    <mat-form-field appearance="outline" class="mt-2">
                        <input matInput placeholder="Enter last name" formControlName="last_name" />
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <mat-label class="mb-2"><b>E-Mail *</b></mat-label>
                <mat-form-field appearance="outline" class="mt-2">
                    <input matInput placeholder="Enter email" formControlName="email" />
                </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-label class="mb-2"><b>Phone number *</b></mat-label>
                <mat-form-field appearance="outline" class="mt-2">
                    <input matInput placeholder="Enter phone" formControlName="phone_number" />
                </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                    <p class="mb-2"><b>Inquiry *</b></p>
                    <mat-form-field appearance="outline" class="example-full-width">
                        <textarea matInput placeholder="Type your message here..." formControlName="inquiry"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-10 text-end">
                    <button type="submit" class="btn btn-dark" ><b>SEND INQUIRY</b></button>
                </div>
            </div>
        </form>
    </div>
</main>