<div class="main-banner-home pt-70">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="main-banner-text">
            <h1 class="mobile-font-size pc-font-size">CEA & BYD</h1>
            <p style="color:#EFE6C0; font-size:large">The energy for a better tomorrow</p><br>
            <div class="row justify-content-center">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-banner main-banner-imagen">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="card-cea" style="opacity: 0.94;">
            <div class="card-body" style="padding-top: 20px; padding-bottom: 20px;">
              <div class="row align-items-center ">
                <div class="col-lg-6 col-md-12">
                  <div class="about-content">
                    <div class="section-title">
                      <h1 style="text-align: left; font-size: 50px">We are CEA</h1><br>
                      <p class="fuente-pop-reg">At Collective Energy, our mission is to transform energy storage across Africa with top-tier BYD BatteryBox LFP products. We began our journey in Kenya, where we quickly became the leading distributor of lithium-ion batteries in East Africa. Building on this success, we expanded our reach and expertise to the rest of the continent, bringing reliable and innovative energy solutions to a wider audience.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 text-center">
                  <div class="about-video">
                    <img src="/assets/img/CEA_black.svg" style="height: 21rem;" alt="about">
                    <div class="video-btn">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <article class="main-banner-home pt-70  d-flex justify-content-center align-items-center">
    <div class="  flex-column">
      <h2 class="texto-blanco d-flex justify-content-center">
        THE COLLECTIVE ENERGY GROUP
      </h2>
      <div class="row">
        <div class="col-2 p-0"></div>
        <div class="col-8">
          <p class="texto-secundario text-center">
            Today, CEA is more than just a company—it’s a group that unites three powerhouse entities under one banner, each
            committed to advancing sustainable energy solutions in their regions:
          </p>
        </div>
        <div class="col-2"></div>
      </div>
      <div class="d-flex flex-row justify-content-evenly row mt-5 gap-3 p-2"> 
        <div class="col-sm-12 col-lg-3">
          <h6 class="texto-verde text-center">KENYA</h6>
          <p class="texto-blanco justificar">The birthplace of our mission and
            the foundation of our expertise. As
            the largest distributor of lithium-ion
            batteries in East Africa, Collective
            Energy Kenya continues to lead
            the charge in delivering
            cutting-edge energy storage
            solutions.</p>
        </div>
        <div class="col-sm-12 col-lg-3">
          <h6 class="texto-verde text-center">UGANDA</h6>
          <p class="texto-blanco justificar">Expanding our footprint in East
            Africa, Collective Energy Uganda
            is dedicated to providing the same
            level of quality and service that has
            made us a trusted name in Kenya.
            Our Ugandan operations focus on
            empowering local communities
            with reliable energy storage and
            technical support.</p>
        </div>
        <div class="col-sm-12 col-lg-3">
          <h6 class="texto-verde text-center">SOUTH AFRICA</h6>
          <p class="texto-blanco justificar">As our southernmost presence,
            Collective Energy South Africa
            serves as a hub for our operations
            in the southern regions of the
            continent. We offer BYD batteries
            tailored to local needs, ensuring
            reliable power for homes and
            businesses alike..</p>
        </div>
      </div>

    </div>


  </article>
  

  <div class="main-banner-byd ptb-100">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="battery battery-cea">
            <span class="m-5" style="color:#FFFFFF">BYD PARTNERS</span>
          </div>
          <div class="card-byd" style="opacity: 0.9;">
            <div class="card-body" style="padding: 40px;">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-6 col-md-12">
                  <div class="about-content text-center">
                    <div class="section-title">
                      <div class="about-video">
                        <img src="assets/img/cealogos.png" alt="about">
                        <div class="video-btn">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 text-left">
                  <h1 class=""style="text-align: left; font-size: 50px;margin-left:0.8em;">Build Your Dreams</h1><br>
                  <p class="text-justify fuente-pop-reg" style="margin-left:3em; margin-right:3em">BYD (Bluid Your Dreams) is the world's largest manufacturer of solar batteries as well as a leading supplier of electric vehicles. Globally, BYD is one of the most successful, innovative, and fastest-growing companies. <br><br>
                    BYD was founded in February 1995 in Shenzhen, China, with only 20 workers. Today, BYD employs more than 220,000 people worldwide, including production and sales locations in Europe, America, Japan, India, and many more. <br><br>
                    BYD is the first large-scale enterprise involved in the entire energy chain - from generation to storage to consumption to achieve a sustainable energy industry.</p>
                  <div class="button-container">
                    <a href="https://www.bydbatterybox.com/" target="_blank" class="btn btn-three ms-5 fuente-pop-reg">LEARN MORE ABOUT BYD →</a>
                  </div>
                </div>
  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="bg-fffdfd ptb-100">
    <div class="container">
      <div class="section">
        <div class="">
          <div class="section">
            <div class="battery">
              <span>0 EMISSION</span>
              <span>ECOSYSTEM</span>
            </div>
            <div class="row justify-content-center equal-height">
              <div class=" small-padd">
                <small class="fuente-pop-reg economy-text">BYD is the world's largest manufacturer of electric vehicles. From electric forklifts to <br>
                  double decker buses in London or the new Skyrail - BYD electrifies all means of <br>
                  transport to create an emission-free ecosystem.
                </small>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="">
                  <div class="services-content">
                    <h3><span class="borders-top">Solar P</span>ower <br> Generation</h3> <br>
                  </div>
                  <div class="services-img">
                    <img src="assets/img/Solar.jpg" height="250"width="350" alt="services-img">
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="">
                    <div class="services-content">
                        <h3 ><span class="borders-top">Lighting</span>  Electrified <br> Transportation</h3> <br>                            
                    </div>
                    <div class="services-img">
                        <img src="assets/img/Lighting.jpg" height="250"width="350" alt="services-img">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="">
                    <div class="services-content">
                        <h3><span class="borders-top">Energy</span> <br> Storage</h3> <br>                           
                    </div>
                    <div class="services-img">
                        <img src="assets/img/EnergyStorage.jpg" height="250" width="350" alt="services-img">
                    </div>
                </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
