<footer class="footer-area">
  <div class="container">
        <div class="col-md-6 mb-5">
          <img src="/assets/img/CE_black.png" alt="Logo">
      </div>
    <div class="row">
      <!-- Sección KENYA -->
      <div class="col-md-3 borders-top">
        <p class="mt-3 fuente-pop titulo-footer">COLLECTIVE ENERGY AFRICA KENYA</p>
        <p style="font-size:small;" class="texto-negro">+254 712 988 211</p>
        <p style="font-size:small;" class="texto-negro">6th floor Morningside Office Park, Kilimani, Nairobi, Kenya</p>
        <p style="font-size:small;" class="texto-negro">v.ombajo@collective-energy.africa</p>
      </div>
    
      <!-- Sección UGANDA -->
      <div class="col-md-3 borders-top">
        <p class="mt-3 fuente-pop titulo-footer">COLLECTIVE ENERGY AFRICA UGANDA</p>
        <p style="font-size:small;" class="texto-negro">+256 756 571 836</p>
        <p style="font-size:small;" class="texto-negro">Nakasero, Central Division, Kampala, Uganda</p>
        <p style="font-size:small;" class="texto-negro">henry@collective-energy.africa</p>
      </div>
    
      <!-- Sección SOUTH AFRICA -->
      <div class="col-md-3 borders-top">
        <p class="mt-3 fuente-pop titulo-footer">COLLECTIVE ENERGY SOUTH AFRICA</p>
        <p style="font-size:small;" class="texto-negro">+27 661 583 276</p>
        <p style="font-size:small;" class="texto-negro">18a Gill Street, Saiee House,  Johannesburg, South Africa.</p>
        <p style="font-size:small;" class="texto-negro">hugo@collective-energy.africa</p>
      </div>
    
      <!-- Sección MEMBER OF -->
      <div class="col-md-3 borders-top d-flex align-items-start">
        <div>
          <p style="font-size:small; font-weight:bold;">MEMBER OF:</p>
          <div>
            <img src="/assets/img/logoAFSIA.png" alt="Logo" style="max-width:100%;">
          </div>
        </div>
      </div>
    </div>
          
  </div>
</footer>