<main style="background-color: #f2f2f2;padding-top: 10%;">
        <div style="padding-left: 10%;">
            <h2 [innerHTML]="'I HAVE A PROJECT TO QUOTE'"></h2>
        
        <mat-stepper labelPosition="bottom" #stepper linear="true" >
            <mat-step [stepControl]="firstFormGroup">
            <form [formGroup]="firstFormGroup">
                <ng-template matStepperIcon="edit">
                    <mat-icon>✓</mat-icon>
                  </ng-template>
                  <ng-template matStepLabel><span>
                    PROJECT DETAILS
                  </span></ng-template>
                <div class="mt-5 mb-4" style="color:black">
                    <h2><b [innerHTML]="'PROJECT DETAILS'"></b></h2>
                </div>
                <div class="row">
                    <div class="col-4">
                        <mat-label class="mb-2"><b [innerHTML]="'Project title *'"></b></mat-label>
                        <mat-form-field appearance="outline" class="mt-2">
                            <input matInput placeholder="{{'Enter your title' }}" formControlName="project_title" required />
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-label><b>Estimated Energisation Date *</b></mat-label>
                        <mat-form-field appearance="outline" class="mt-2">
                            <input matInput [matDatepicker]="picker" formControlName="estigmated_energisation_date" required>
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                          </mat-form-field>
                    </div>
                </div>
                  <div class="row">
                    <div class="col-4">
                        <mat-label class="mb-2"><b [innerHTML]="'Project location *' "></b></mat-label>
                        <mat-form-field appearance="outline" class="mt-2">
                            <input matInput placeholder="{{'Enter location'}}" formControlName="project_location" required />
                          </mat-form-field>
                    </div>
                  </div>
                <div>
                <div class="row">
                    <div class="col-10">

                    </div>
                    <div class="col-2">
                        <button mat-button matStepperNext>
                            <img src="/assets/img/next.png" alt="">
                        </button>
                    </div>
                </div>
                
                </div>
            </form>
            </mat-step>
            <mat-step [stepControl]="secondFormGroup">
            <form [formGroup]="secondFormGroup">
                <ng-template matStepLabel [innerHTML]="'TECHNICAL SPECIFICATIONS' "></ng-template>
                <div>
                    <div class="mt-5 mb-4" style="color:black">
                        <h2><b [innerHTML]="'TECHNICAL SPECIFICATIONS' "></b></h2>
                    </div>

                    <div class="row">
                        <div class="col-3">
                            <mat-label class="mb-2"><b [innerHTML]="'Required power *'"></b></mat-label>
                            <mat-form-field appearance="outline" class="mt-2">
                                <input matInput placeholder="{{'Enter power'}}" formControlName="required_power" required />
                                <span style="margin-right:5px;" matSuffix>kW</span>
                            </mat-form-field>
                        </div>
                        <div class="col-3">
                                <mat-label class="mb-2"><b [innerHTML]="'Required energy *' "></b></mat-label>
                            <mat-form-field appearance="outline" class="mt-2">
                                <input matInput placeholder="{{'Enter energy' }}" formControlName="required_energy" required />
                                <span style="margin-right:5px;" matSuffix>kWh</span>
                            </mat-form-field>
                        </div>
                        <div class="col-3">
                            <p class="mb-1"><b>Measured at *</b></p>
                            <mat-form-field appearance="outline"  >
                                <mat-select formControlName="project_purpose">
                                    <mat-option value="Beginning of Life (BOL)">
                                        {{"Beginning of Life (BOL)"}}
                                    </mat-option>
                                    <mat-option value="End of Life (EOL)">
                                        {{"End of Life (EOL)"}}
                                    </mat-option>
                                </mat-select>
                              </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-3">
                            <p class="mb-2"><b [innerHTML]="'Project lifetime *'"></b></p>
                            <mat-form-field appearance="outline"  >
                                <mat-select formControlName="project_lifetime">
                                    <mat-option value="5 years">
                                        {{"5 years"}}
                                    </mat-option>
                                    <mat-option value="10 years">
                                        {{"10 years"}}
                                    </mat-option>
                                    <mat-option value="15 years">
                                        {{"15 years"}}
                                    </mat-option>
                                    <mat-option value="20 years">
                                        {{"20 years"}}
                                    </mat-option>
                                </mat-select>
                              </mat-form-field>
                        </div>
                    </div>

                    <div class="row mb-4">
                        <div class="col-3">
                            <p><b [innerHTML]="'Number of cycles per day *' "></b></p>
                            <mat-radio-group formControlName="number_cycles_per_day" >
                                <mat-radio-button class="me-2" style="margin-left: 16px;" value="1 cycles">
                                    {{"<=1 cycles" }}
                                </mat-radio-button>
                                <mat-radio-button value="1.5 cycles" >
                                    {{"1.5 cycles" }}
                                </mat-radio-button> <br>
                                <mat-radio-button class="me-2" value="2 cycles">
                                    {{"2 cycles" }}
                                </mat-radio-button>
                                <mat-radio-button value="2.5 cycles">
                                    {{"2.5=< cycles" }}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-3">
                            <p class="mb-2"><b>
                                {{"On-grid or off-grid" }} *</b></p>
                            <mat-radio-group formControlName="on_grid_off_grid" >
                                <mat-radio-button class="me-2" value="On-grid" >
                                    {{"On-grid" }}
                                </mat-radio-button>
                                <mat-radio-button value="Off-grid" >
                                    {{"Off-grid" }}
                                </mat-radio-button><br>
                                <mat-radio-button style="margin-left: 0;" value="">
                                    {{"On-Grid+Backup" }}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-3">
                            <p class="mb-2"><b>Coupled with generation *</b></p>
                            <mat-form-field appearance="outline"  >
                                <mat-select formControlName="coupled_generation" multiple>
                                    <mat-option value="None">
                                        {{"None" }}
                                    </mat-option>
                                    <mat-option value="Solar PV">
                                        {{"Solar PV" }}
                                    </mat-option>
                                    <mat-option value="Wind">
                                        {{"Wind" }}
                                    </mat-option>
                                    <mat-option value="Genset">
                                        {{"Genset" }}
                                    </mat-option>
                                </mat-select>
                              </mat-form-field>
                        </div>
                        <div class="col-3">
                            <p class="mb-2"><b [innerHTML]="'AC or DC Coupling for Solar PV *' "></b></p>
                            <mat-radio-group formControlName="coupling_solar_pv">
                                <mat-radio-button value="AC coupling">
                                    {{"AC Coupling (Stand Alone or Hybridization AC-DC)" }}
                                </mat-radio-button><br>
                                <mat-radio-button style="margin-left: 0;" value="DC coupling">
                                    {{"DC Coupling (Hybridization DC-DC, no grid charging)'" }}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-3">
                            <p class="mb-2"><b [innerHTML]="'AC Voltage Level at PoC*' "></b></p>
                            <mat-form-field appearance="outline" >
                                <mat-select formControlName="voltage_level">
                                    <mat-option value="Low Voltage">
                                        {{"Low Voltage (LV): 400V" }}
                                    </mat-option>
                                    <mat-option value="Medium Voltage">
                                        {{"Medium Voltage (MV): 6,6kV - 35kV" }}
                                    </mat-option>
                                    <mat-option value="Others">
                                        {{"Others" }}
                                    </mat-option>
                                </mat-select>
                              </mat-form-field>
                        </div>
                        <div class="col-3">
                            <p class="mb-2"><b [innerHTML]="'Energy management system (EMS)*' "></b></p>
                            <mat-form-field appearance="outline" >
                                <mat-select formControlName="ems">
                                    <mat-option value="Yes">
                                        {{"Yes" }}
                                    </mat-option>
                                    <mat-option value="No">
                                        {{"No" }}
                                    </mat-option>
                                </mat-select>
                              </mat-form-field>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-3">
                            <p class="mb-2"><b [innerHTML]="'Primary application*' "></b></p>
                            <mat-form-field appearance="outline" >
                                <mat-select formControlName="primary_application" multiple>
                                    <mat-option value="Backup">
                                        {{"Backup" }}
                                    </mat-option>
                                    <mat-option value="Avoid grid upgrades">
                                        {{"Avoid grid upgrades" }}
                                    </mat-option>
                                    <mat-option value="Peak Shaving">
                                        {{"Peak Shaving" }}
                                    </mat-option>
                                    <mat-option value="Self-consumtion">
                                        {{"Self-consumtion" }}
                                    </mat-option>
                                    <mat-option value="Arbitrage / Time shifting">
                                        {{"Arbitrage / Time shifting" }}
                                    </mat-option>
                                    <mat-option value="Grid services" >
                                        {{"Grid services" }}
                                    </mat-option>
                                </mat-select>
                              </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <mat-label><b [innerHTML]="'Other comments*' "></b></mat-label>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <textarea matInput formControlName="comment"></textarea>
                              </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-10">
                        </div>
                        <div class="col-2">
                            <button mat-button matStepperPrevious>
                                <img src="/assets/img/back.png" alt="">
                            </button>
                            <button mat-button matStepperNext>
                                <img src="/assets/img/next.png" alt="">
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            </mat-step>
            <mat-step [stepControl]="thirdFormGroup">
                <form [formGroup]="thirdFormGroup">
                    <ng-template matStepLabel [innerHTML]="'COMPANY INFORMATION' "></ng-template>
                    <div>
                        <div class="mt-5 mb-4" style="color:black">
                            <h2><b [innerHTML]="'COMPANY INFORMATION' "></b></h2>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4">
                            <mat-label class="mb-2"><b [innerHTML]="'Company Name *' "></b></mat-label>
                        <mat-form-field appearance="outline" class="mt-2">
                            <input matInput placeholder="{{'Enter name' }}" formControlName="company_name" />
                          </mat-form-field>
                        </div>
                    </div>

                    <h4 style="margin: 32px 0 16px;" class="mb-4 mt-4"><b [innerHTML]="'COMPANY CONTACT PERSON' "></b></h4>
                    <div class="row">
                        <div class="col-4">
                            <mat-label class="mb-2"><b [innerHTML]="'First Name *' "></b></mat-label>
                        <mat-form-field appearance="outline" class="mt-2">
                            <input matInput placeholder="{{'Enter name' }}" formControlName="first_name" />
                          </mat-form-field>
                        </div>
                        <div class="col-4">
                            <mat-label class="mb-2"><b [innerHTML]="'Last Name *' "></b></mat-label>
                            <mat-form-field appearance="outline" class="mt-2">
                                <input matInput placeholder="{{'Enter last name' }}" formControlName="last_name" />
                              </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <mat-label class="mb-2"><b [innerHTML]="'E-Mail *' "></b></mat-label>
                        <mat-form-field appearance="outline" class="mt-2">
                            <input matInput placeholder="{{'Enter E-Mail' }}" formControlName="email" />
                          </mat-form-field>
                        </div>
                        <div class="col-4">
                            <mat-label class="mb-2"><b [innerHTML]="'Phone number *' "></b></mat-label>
                        <mat-form-field appearance="outline" class="mt-2">
                            <input matInput placeholder="{{'Enter phone' }}" formControlName="phone_number" />
                          </mat-form-field>
                        </div>
                    </div>

                    <h4 style="margin: 32px 0 16px;" class="mb-4 mt-4"><b [innerHTML]="'COMPANY ADDRESS' "></b></h4>
                    <div class="row">
                        <div class="col-8">
                            <mat-label class="mb-2"><b [innerHTML]="'Address *' "></b></mat-label>
                            <mat-form-field appearance="outline" class="mt-2">
                                <input matInput placeholder="{{'Enter your address' }}" formControlName="address" required />
                            </mat-form-field>
                        </div>
                    </div>
                      <div class="row">
                        <div class="col-2">
                            <mat-label class="mb-2"><b [innerHTML]="'Zip code *' "></b></mat-label>
                            <mat-form-field appearance="outline" class="mt-2">
                                <input matInput placeholder="{{'Enter your zip code' }}" formControlName="zip_code" required />
                              </mat-form-field>
                        </div>
                        <div class="col-3">
                            <mat-label class="mb-2"><b [innerHTML]="'City *' "></b></mat-label>
                            <mat-form-field appearance="outline" class="mt-2">
                                <input matInput placeholder="{{'Enter your city' }}" formControlName="city" required />
                              </mat-form-field>
                        </div>
                        <div class="col-3">
                            <mat-label class="mb-2"><b [innerHTML]="'Country *' "></b></mat-label>
                            <mat-form-field appearance="outline" class="mt-2">
                            <mat-select formControlName="country" required>
                                <mat-option *ngFor="let country of countries" value="{{ country.name }}">{{country.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        </div>
                      </div>
                    <div class="row">
                        <div class="col-10">
                        </div>
                        <div class="col-2">
                            <button mat-button matStepperPrevious>
                                <img src="/assets/img/back.png" alt="">
                            </button>
                            <button mat-button matStepperNext>
                                <img src="/assets/img/next.png" alt="">
                            </button>
                        </div>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="finalFormGroup">
                <form [formGroup]="finalFormGroup">
                    <ng-template matStepLabel [innerHTML]="'SUBMIT' "></ng-template>
                    <div>
                        <div class="mt-5 mb-4" style="color:black">
                            <h2><b [innerHTML]="'SUBMIT' "></b></h2>
                        </div>
                    </div>

                    <div class="row mb-4">
                        <div class="col-md-12 col-sm-12 col-lg-6">
                            <div class="row" style="background-color: #f2f2f2; align-items: center; padding: 8px;">
                                <div class="col-md-12 col-sm-12 col-lg-6">
                                    <mat-label><b [innerHTML]="'Upload supporting documents' "></b></mat-label>
                                    <p [innerHTML]="'Upload Supporting Documents (e.g., Tender Documents, Technical Drawings)' "></p>
                                </div>
                                <div class="col-md-12 col-sm-12 col-lg-6">
                                    <input class="form-control" type="file" id="formFile" formControlName="supporting_documents" (change)="fileSelectionSupportChanged($event)">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-md-12 col-sm-12 col-lg-6">
                            <mat-label class="mb-2"><b [innerHTML]="'Standard NDA *' "></b></mat-label><br>
                            <mat-checkbox class="example-margin" formControlName="standard_nda">
                                {{ 'I have read the NDA and accept the'  }}
                                <a href="/assets/pdf/oneNDA - EFT Systems.docx.pdf" target="_blank">
                                  {{ 'Terms'  }}
                                </a>.
                              </mat-checkbox>
                              
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-md-12 col-sm-12 col-lg-6">
                            <div class="row" style="background-color: #f2f2f2; align-items: center; padding: 8px;">
                                <div class="col-md-12 col-sm-12 col-lg-6">
                                    <mat-label><b [innerHTML]="'Upload NDA' "></b></mat-label>
                                    <p [innerHTML]="'If required, please upload your own NDA for review.' "></p>
                                </div>
                                <div class="col-md-12 col-sm-12 col-lg-6">
                                    <input class="form-control" type="file" id="formFile" formControlName="nda" (change)="fileSelectionNdaChanged($event)">
                                </div>
                            </div>
                        </div>                        
                    </div>

                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-lg-7">
                        </div>
                        <div class="col-md-12 col-sm-12 col-lg-2 text-end">
                            <button mat-button matStepperPrevious>
                                <img src="/assets/img/back.png" alt="">
                            </button>
                        </div>
                        <div class="col-md-12 col-sm-12 col-lg-3">
                            <button mat-button matStepperNext>
                                <button type="button" (click)="submit()" class="btn btn-dark" [disabled]="sending" [innerHTML]="'SEND QUOTE REQUEST'"><b></b></button>
                            </button>
                        </div>
                    </div>
                </form>
            </mat-step>
        </mat-stepper>
    </div>
    </main>