<div class="main-banner-home pt-70">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="main-banner-text">
            <h1 class="mobile-font-size pc-font-size">Your trusted partner in EV<br> charging infrastructure</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-banner-byd">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="" style="opacity: 0.9;">
            <div class="card-body" >
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-6 col-md-12">
                  <div class="about-content text-center">
                    <div class="section-title">
                      <div class="about-video">
                        <img src="/assets/img/Coche.jpg" alt="about">
                        <div class="video-btn">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 text-left">
                  <p class="text-justify fuente-pop-reg" style="color: #efe6c0; ">As electric vehicles become the future of transportation, ensuring a reliable and efficient charging infrastructure is crucial. At CEA, we offer comprehensive EV charging solutions tailored to residential, commercial, and industrial needs. From seamless installation to ongoing maintenance and advanced energy management, our services are designed to keep your charging points fully operational and optimized.</p>
                </div>
  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pb-70 pt-70" style="background-color:#0b1f0d;">
    <div class="section">
<div class="battery" >
    <h1 class="mobile-font-size pc-font-size" style="color: #ffffff;">WHAT WE OFFER</h1>
    </div>         
    <br><br> 
    <div class="container">
        <div class="row d-flex flex-row gap-3 justify-content-center">
          <div class="col-md-2">
            <div class="">
              <div class="caja residential-img">
                <img src="/assets/img/Icono_1.svg" height="100px" alt="residential-img">
              </div>
              <div class="title-h4 mt-2">
                <h4  class="mt-2">Residential charging points</h4><br>
                </div>
                <div class="letra-iconos">
                  <small class="fuente-pop-reg">Efficient and safe installation of home EV charging points tailored to your needs.</small>

                </div>

            </div>
          </div>
          <div class="col-md-2">
            <div class=""  >
              <div class="caja services-img" style="vertical-align: top;">
                <img src="/assets/img/Icono_2.svg" height="100px" alt="services-img">
              </div>
              <div class="title-h4 mt-2">
                <h4 class="mt-2">Commercial charging points</h4>
                </div>
                <div class="letra-iconos">
                <small class="fuente-pop-reg">Scalable charging solutions for businesses, ensuring seamless integration with your operations.</small>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class=""  >
              <div class="caja services-img" style="vertical-align: top;">
                <img src="/assets/img/Icono_3.svg" height="100px" alt="services-img">
              </div>
              <div class="title-h4 mt-2">
                <h4 class="mt-2">Project development</h4>
                </div>
                <div class="letra-iconos">
                <small class="fuente-pop-reg">Comprehensive EV charging project development, from planning to implementation.</small>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class=""  >
              <div class="caja services-img" style="vertical-align: top;">
                <img src="/assets/img/Icono_4.svg" height="100px" alt="services-img">
              </div>
              <div class="title-h4 mt-2">
                <h4 style="color:#ffffff">Maintenance and service</h4>
              </div>
              <div class="letra-iconos">
                <small class="fuente-pop-reg">Reliable maintenance and prompt service to keep your charging points operational.</small>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class=""  >
              <div class="caja services-img" style="vertical-align: top;">
                <img src="/assets/img/Icono_5.svg" height="100px" alt="services-img">
              </div>
              <div class="title-h4 mt-2">
                <h4 style="color:#ffffff">Energy Management System (EMS)</h4>
                </div>
                <div class="letra-iconos">

                <small class="fuente-pop-reg">Advanced EMS integration for optimizing complex EV charging infrastructures.</small>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>
</div>
