<div class="main-banner-home">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="main-banner-text-ci">
                <h1 class="mobile-font-size pc-font-size" style="color:#ffffff; margin-top: 0.1em;margin-bottom: 0em;">C&I Solutions</h1>
                <p class="text-center" style="color: #EFE6C0; margin-top: 0; font-size: large;">Reliable, cost-effective, and scalable Commercial & Industrial (C&I) energy solutions by BYD, tailored to your business needs.</p>
            </div>
        </div>
    </div>
</div>
<div class=" bg-fffdfd   pb-70" id="welcome-raion">
    <div class="container">
        <div class="section">
<div class="   " id="welcome-raion">
    <div class="section">
        <div class="battery-new mb-5 pt-5">
            <div class="battery-c mb-5">
                <span>WHAT WE OFFER</span>
                </div>                     </div>         
            <br>   
            <div class="row justify-content-center equal-height">
            <div class="col-lg-4 col-md-6">
                <div class="">
                    <div class="services-content title-space">
                        <h3>Custom energy solutions</h3>                         
                    </div>
                    <div class="services-img mt-3">
                        <img src="/assets/img/ci1.png" alt="services-img">
                    </div>
                    <div class="services-content">
                        <small>Tailored battery storage and solar solutions, including BYD batteries, power electronics, and advanced energy management systems.
                        </small>
                        
                    </div>
                </div>
            </div>
<div class="col-lg-4 col-md-6">
    <div class="">
        <div class="services-content title-space">
            <h3 >Turnkey project delivery</h3>
            </div>
        <div class="services-img mt-3">
            <img src="/assets/img/medio-ci.jpeg" alt="services-img">
        </div>
        <div class="services-content">
            <small>Full-service support from design and engineering to installation and long-term maintenance.
            </small>
            
        </div>
    </div>
</div>
<div class="col-lg-4 col-md-6">
    <div class="">
        <div class="services-content title-space">
            <h3>Local expertise, global support</h3>                         
        </div>
        <div class="services-img mt-3">
            <img src="/assets/img/ciderecha.jpeg" alt="services-img">
        </div>
        <div class="services-content">
            <small>A dedicated African team backed by EFT-Systems, a European leader in C&I energy solutions.
            </small>
        </div>
    </div>
</div>
</div>
</div>
</div>
</div>
    </div>
</div>
<div class="pb-70 pt-70" style="background-color:#0b1f0d;">
    <div class="section">
        <div class="battery">
            <h1 class="mobile-font-size pc-font-size" style="color: #ffffff;">OUR SOLUTIONS</h1>
        </div>         
        <div class="d-flex justify-content-center">
            <p class="texto-blanco">
                Our systems are designed to meet the energy needs of businesses across various industries and applications.
            </p> 
        </div>
        <br><br> 
        <section class="use-cases-img row mt-5">
            <div class="imagenes">
                <div class="col-md-2">
                    <a style="cursor: pointer;">
                        <img class="imagen" src="/assets/img/ci22.jpg" alt="">
                        <div class="mt-2 sub-title-space">
                            <b class="texto-blanco ">Grid outage protection</b>

                        </div>
                        <div class="mt-4 ">
                            <small class="texto-blanco text-space">Keep operations running during grid outages with reliable energy storage solutions.
                            </small>
                        </div>
                        <div class="mt-4 brouchure">
                            <a href="/assets/pdf/130225_CEA_brochure_c&i_case1.pdf" target="_blank" class="texto-negro boton-brouchure p-2">Download case brochure</a>
                        </div>
                    </a>
                </div>
                <div class="col-md-2">
                    <a style="cursor: pointer;">
                        <img class="imagen" src="/assets/img/ci331.png" alt="">
                        <div class="mt-2 sub-title-space">
                        <b class="texto-blanco">Energy cost optimization</b>
                        </div>
                        <div class="mt-4 ">
                        <small class="texto-blanco">Store energy during off-peak
                            hours and use it when electricity
                            costs are highest.</small>
                            </div>
                    </a>
                    <div class="mt-4 brouchure">
                        <a href="/assets/pdf/130225_CEA_brochure_c&i_case2.pdf" target="_blank" class="texto-negro boton-brouchure p-2">Download case brochure</a>
                    </div>
                </div>
                <div class="col-md-2">
                    <a style="cursor: pointer;">   
                        <img class="imagen" src="/assets/img/Cut_diesel.png" alt="">
                        <div class="mt-2 sub-title-space">
                        <b class="texto-blanco">Diesel cost reduction</b>
                        </div>
                        <div class="mt-4">
                        <small class="texto-blanco">Integrate battery storage with
                            diesel generators to lower fuel
                            costs and extend operating
                            hours.</small>
                            </div>
                    </a>
                    <div class="mt-4 brouchure">
                        <a href="/assets/pdf/130225_CEA_brochure_c&i_case3.pdf"target="_blank" class="texto-negro boton-brouchure p-2">Download case brochure</a>
                    </div>
                </div>
                <div class="col-md-2">
                    <a style="cursor: pointer;">
                        <img class="imagen" src="/assets/img/ROI.jpg" alt="">
                        <div class="mt-2 sub-title-space">
                        <b class="texto-blanco">Scalable growth solutions</b>
                        </div>
                        <div class="mt-4">
                        <small class="texto-blanco">Expand your facilities without
                            expensive grid upgrades by
                            integrating a smart BESS
                            system.</small>
                            </div>
                    </a>
                    <div class="mt-4 brouchure">
                        <a href="/assets/pdf/130225_CEA_brochure_c&i_case4.pdf"target="_blank" class="texto-negro boton-brouchure p-2">Download case brochure</a>
                    </div>
                </div>
                <div class="col-md-2">
                    <a style="cursor: pointer;">
                        <img class="imagen" src="/assets/img/Grow.jpg" alt="">
                        <div class="mt-2 sub-title-space">
                        <b class="texto-blanco ">Maximizing renewable energy</b>
                        </div>
                        <div class="mt-4">
                        <small class="texto-blanco">Store excess solar energy for
                            later use or feed it back to the
                            grid to maximize profitability.</small>
                            </div>
                    </a>
                    <div class="brouchure">
                        <a href="/assets/pdf/130225_CEA_brochure_c&i_case5.pdf"target="_blank" class="texto-negro boton-brouchure p-2">Download case brochure</a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<div class="bg-fffdfd pt-70 pb-70" id="welcome-raion">
<div class="container resp-mt-2">
    <div class="section">
        <div class="parrafos">
            <div class="col-lg-6 welcome-title1">
                <h1>PRODUCTS</h1>
            </div>
            <div class="col-lg-6 mt-3 fuente-pop-reg welcome-title2">

            </div>
        </div>
        <br>
        <div *ngIf="!abrirFlex && !abrirPlus" class="row justify-content-center">
            <div class="col-lg-5 col-md-6" (click)="flex()">
                <div class="single-services-byd space-img">
                    <div class="services-content">
                        <h3 class="fuente-cool-light">INDUSTRIAL FLEX</h3>
                    </div>
                    <div class="container-img text-center">
                        <img src="/assets/img/flex1.png" height="80%" alt="services-img">
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-6" (click)="plus()">
                <div class="single-services-byd">
                    <div class="services-content">
                        <h3 class="fuente-cool-light">INDUSTRIAL PLUS</h3>
                    </div>
                    <div class="container-img text-center">
                        <img src="/assets/img/Container.png"  alt="services-img">
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="abrirFlex" class="row justify-content-center">
            <div *ngIf="abrirFlex" class="col-lg-3 col-md-4">
                <div class="single-services-byd space-img">
                    <div class="services-content">
                        <h4 class="fuente-cool-light">INDUSTRIAL FLEX 1</h4>
                    </div>
                    <div class="container-text text-center d-flex algin-text-center">
                        <ul class="text-start">
                            <li>● Power: 200kW (x1 SINENG PCS Module)</li>
                            <li class="mt-3">● Energy Capacity: 466kWh Cell Energy (x1 BYD MC Cube Cabinet)</li>
                            <li class="mt-3">● Nominal AC Voltage: 400Vac (±15%)</li>
                            <li class="mt-3">● Dimensions (WxDxH): 2,250 x 1,238 x 2,774mm</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="single-services-byd space-img">
                    <div class="services-content">
                        <h4 class="fuente-cool-light">INDUSTRIAL FLEX 2</h4>
                    </div>
                    <div class="container-text text-center">
                        <ul class="text-start">
                            <li>● Power: 200kW (x1 SINENG PCS Module)</li>
                            <li class="mt-3">● Energy Capacity: 932kWh Cell Energy (x2 BYD MC Cube Cabinets)</li>
                            <li class="mt-3">● Nominal AC Voltage: 400Vac (±15%)</li>
                            <li class="mt-3">● Dimensions (WxDxH): 3,380 x 1,238 x 2,774mm</li>
                        </ul>                        </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="single-services-byd">
                    <div class="services-content">
                        <h4 class="fuente-cool-light">INDUSTRIAL FLEX 3</h4>
                    </div>
                    <div class="container-text text-center">
                        <ul class="text-start">
                            <li>● Power: 400kW (x2 SINENG PCS Modules)</li>
                            <li class="mt-3">● Energy Capacity: 932kWh Cell Energy (x2 BYD MC Cube Cabinets)</li>
                            <li class="mt-3">● Nominal AC Voltage: 400Vac (±15%)</li>
                            <li class="mt-3">● Dimensions (WxDxH): 3,380 x 1,238 x 2,774mm</li>
                        </ul>                        
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center mt-3">
                <button class="volver" (click)="back()">Back</button>
            </div>
        </div>
        <div *ngIf="abrirPlus" class="row justify-content-center">
            <div class="col-lg-3 col-md-4">
                <div class="single-services-byd space-img">
                    <div class="services-content">
                        <h4 class="fuente-cool-light">INDUSTRIAL PLUS 1</h4>
                    </div>
                    <div class="container-text text-center d-flex algin-text-center">
                        <ul class="text-start">
                            <li>● Power: 860kW</li>
                            <li class="mt-3">● Energy Capacity (Cell Energy): 1,864kWh (x4 BYD MC Cube Cabinets)</li>
                            <li class="mt-3">● Nominal AC Voltage: 690Vac (±15%)</li>
                            <li class="mt-3">● Dimensions (WxDxH): 20ft HQ Container</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="single-services-byd space-img">
                    <div class="services-content">
                        <h4 class="fuente-cool-light">INDUSTRIAL PLUS 2</h4>
                    </div>
                    <div class="container-text text-center">
                        <ul class="text-start">
                            <li>● Power: 1,075kW</li>
                            <li class="mt-3">● Energy Capacity (Cell Energy): 2,330kWh (x5 BYD MC Cube Cabinets)</li>
                            <li class="mt-3">● Nominal AC Voltage: 690Vac (±15%)</li>
                            <li class="mt-3">● Dimensions (WxDxH): 20ft
                                HQ Container</li>
                        </ul>                        </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="single-services-byd">
                    <div class="services-content">
                        <h4 class="fuente-cool-light">INDUSTRIAL PLUS 3</h4>
                    </div>
                    <div class="container-text text-center">
                        <ul class="text-start">
                            <li>● Power: 1,290kW</li>
                            <li class="mt-3">● Energy Capacity (Cell Energy): 2,796kWh (x6 BYD MC Cube Cabinets)</li>
                            <li class="mt-3">● Nominal AC Voltage: 690Vac (±15%)</li>
                            <li class="mt-3">● Dimensions (WxDxH): 20ft HQ Container</li>
                        </ul>                        
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center mt-3">
                <button class="volver" (click)="back()">Back</button>
            </div>
        </div>

    </div>
</div>
</div>
<div class="pb-70 pt-70" style="background-color:#0b1f0d;">
<div class="container">      
    <div class="d-flex justify-content-left flex-column">
        <p class="texto-blanco">
            Ready to optimize your energy strategy?
        </p> 
        <p class="texto-blanco">
            Get in touch with our team today to explore the best energy solutions for your business.
        </p> 
        <p class="texto-blanco">
            Please select the option that best describes your inquiry, and we’ll guide you
            to the appropriate form:
        </p> 
        <div  class="row justify-content-center mt-5">
            <div class="col-lg-4 col-md-4">
                <div class="card-ci ">
                    <div class="">
                        <h4 class="fuente-cool-light padding">I HAVE A PROJECT TO
                            QUOTE</h4>
                    </div>
                    <div class=" text-left d-flex algin-text-left">
                        <p class="p-5">Are you ready to get a quote
                            for a specific project? Let us
                            know your project details, and
                            our team will provide you with
                            a tailored solution.
                        </p>
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <a href="/project-to-quote" class="texto-negro boton-brouchure p-2">Start quote request</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-4">
                <div class="card-ci ">
                    <div class="">
                        <h4 class="fuente-cool-light padding">I AM AN END CUSTOMER
                            INTERESTED IN BATTERIES</h4>
                    </div>
                    <div class=" text-left d-flex algin-text-left">
                        <p class="p-5">Curious about whether energy
                            storage is the right solution for
                            your needs? Fill out this form,
                            and our experts will guide you
                            through the benefits of battery
                            storage for your specific
                            situation.
                        </p>
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <a href="/customer-interested" class="texto-negro boton-brouchure p-2">Learn more</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-4">
                <div class="card-ci ">
                    <div class="">
                        <h4 class="fuente-cool-light padding">OTHER INQUIRIES</h4>
                    </div>
                    <div class=" text-left d-flex algin-text-left">
                        <p class="p-5">If you have any other
                            questions or would like more
                            information, we’d be happy to
                            assist you. Please reach out
                            to us here.
                        </p>
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <a href="/other-inquiries" class="texto-negro boton-brouchure p-2">General enquiry form</a>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<!-- <div class="bg-fffdfd pt-70 pb-70" id="welcome-raion">
<div class="container resp-mt-2">
    <div class="section">
        <div class="parrafos">
            <div class="col-lg-6 welcome-title1">
                <h1><span class="borders-bottom-green">Com</span>ercial & Industrial</h1>
            </div>
            <div class="col-lg-6 mt-3 fuente-pop-reg welcome-title2">
                <p>Two different product ranges according to your needs. The C&I Solutions are developed hand in hand with BYD.</p>
            </div>
        </div>
        <br>
        <div class="row justify-content-center">
            <div class="col-lg-5 col-md-6">
                <div class="single-services-byd space-img">
                    <div class="services-content">
                        <h3 class="fuente-cool-light">BYD Max Lite</h3>
                        <small class="borders-bottom fuente-pop-reg">30 kWh up to 5.7 MWh</small>
                    </div>
                    <div class="container-img text-center">
                        <img src="/assets/img/BYD-MAX-LIT.png" alt="services-img">
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-6">
                <div class="single-services-byd">
                    <div class="services-content">
                        <h3 class="fuente-cool-light">MC Cube</h3>
                        <small class="borders-bottom fuente-pop-reg">200kW/447kWh</small>
                    </div>
                    <div class="container-img text-center">
                        <img src="assets/img/MCCube.jpg" alt="services-img">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

<div class="p100-70 pt-100 main-banner-home-ci" style="background-color: #0B1F0D;">
<div class="container">
    <div class="section ">
        <div class="battery">
            <span class="KNOW-HOW">CEA & EFT <br> KNOW-HOW</span>
        </div>
        <br><br>
        <div style="text-align: center; width: 100%;">
            <small style="display: inline-block; width: 100%; max-width: 600px; text-align: center; line-height: 1.5; font-size: 0.7rem;color:#ffffff" class="fuente-pop-reg">
                Designing the best Commercial and Industrial (C&I) solution requires carefully selecting the right combination of components. Once configured, the complete solution is delivered to the site for installation. Then, the system is commissioned to ensure it delivers the necessary functions effectively. This approach ensures an optimal and safe system, ready for operation and benefit to all.
            </small>
        </div>
    </div>
</div>
<div class="container">
    <div class="section ">
        <div class="d-flex justify-content-center">
            <a href="https://eft-systems.de/de/" target="_blank">
                <button type="submit" style="height: 40px; width: 230px;" class="btn boton boton-ci fuente-pop-reg">VISIT EFT →</button>
            </a>
        </div>
    </div>
</div>
</div>

<div class="main-banner-ci" style="background-color: #0B1F0D;">
<div class="container " style="color:#ffffff; border-bottom:#ffffff">
    <div class="row">
        <div class="col-md-6 col-sm-12 ico-pad">
            <div class="card-ci" style="border: solid 0.5px; border-radius: 5px; border-color: #ffffff41">
                <div class="card-header-ci table-title" style="background-color: #0B1F0D; border: solid 0.5px; border-radius: 5px; border-color: #ffffff41">C&I Applications</div>
                <div class="card-body-ci" style="background-color: #0B1F0D; border: solid 0.5px; border-radius: 5px; border-color: #ffffff41">
                    <div class="row">
                        <div class=" ico-pad col-md-6">
                            <img src="assets/img/Intelligentenergymanagement.svg" alt="Imagen 1" width="50">
                            <small>Intelligent energy management</small>
                        </div>
                        <div class="col-md-6 ico-pad">
                            <img src="assets/img/Easy plug-and-play installation.svg" alt="Imagen 2" width="50">
                            <small>Easy plug-and-play installation</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 ico-pad">
                            <img src="assets/img/10 years capacity warranty.svg" alt="Imagen 3" width="50">
                            <small>10 years capacity warranty</small>
                        </div>
                        <div class="col-md-6 ico-pad">
                            <img src="assets/img/Safe battery chemistry.svg" alt="Imagen 4" width="50">
                            <small>Safe battery chemistry</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-right ico-pad">
                            <img src="assets/img/Top technical performance.svg" alt="Imagen 5" width="50">
                            <small>Top technical performance</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 mt-4"  >
            <div class="card-ci mt-2" style="border: solid 0.5px; border-radius: 5px; border-color: #ffffff41">
                <div class="card-header-ci table-title" style="background-color: #0B1F0D; border: solid 0.5px; border-radius: 5px; border-color: #ffffff41">C&I Applications</div>
                <div class="card-body-ci" style="background-color: #0B1F0D; border: solid 0.5px; border-radius: 5px; border-color: #ffffff41">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12 ico-pad">
                                            <img src="assets/img/Renewable self consumption.svg" alt="Imagen 6" width="50">
                                        <small>Renewable self consumption</small>
                                        </div>
                                    
                                    <div class="col-md-6 ico-pad">
                                        <img src="assets/img/Time of use.svg" alt="Imagen 7" width="50">
                                        <small>Time of use</small>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 ico-pad">
                                        <img src="assets/img/Peak shaving.svg" alt="Imagen 8" width="50">
                                        <small>Peak shaving</small>
                                    </div>
                                    <div class="col-md-6 ico-pad">
                                        <img src="assets/img/Back-up power.svg" alt="Imagen 9" width="50">
                                        <small>Back-up power</small>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 ico-pad">
                                        <img src="assets/img/EV charging optimization.svg" alt="Imagen 10" width="50">
                                        <small>EV charging optimization</small>
                                    </div>
                                    <div class="col-md-6 ico-pad">
                                        <img src="assets/img/Frequency regulation.svg" alt="Imagen 10" width="50">
                                        <small>Frequency regulation</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
</div>

<div class="pb- pt-100 " style="background-color: #ffffff; ">
<div class="section  ptb-100">
<div class="battery" >
<span style="opacity: 0.5;">Services <br> O&M</span><br>
</div>         
<br><br> 
<div class="container">
    <div class="row " style="padding-left: 100px; padding-right: 100px;">
        <div class="col-md-4 mb-4 ml-5">
            <div class="">
                <div class="icon-border text-center" style="vertical-align: top;">
                    <img src="assets/img/Educate.svg" height="100" alt="services-img">
                </div>
                <div class="text-right"><br>
                    <h3 class="mb-3">Educate</h3>
                    <small class="fuente-pop-reg">· Installer on site training <br> · Re-train e-learning <br> · End customer materials</small>
                </div>
            </div>
        </div>
        <div class="col-md-4 mb-4 ml-5">
            <div class="">
                <div class="icon-border text-center" style="vertical-align: top;">
                    <img src="assets/img/Support.svg" height="100" alt="services-img">
                </div>
                <div class="text-left"><br>
                    <h3 class="mb-3">Support</h3>
                    <small class="fuente-pop-reg">· Bespoke app and monitoring <br> · Connected service portal <br> · Remote support</small>
                </div>
            </div>
        </div>
        <div class="col-md-4 mb-4 mr-5">
            <div class="">
                <div class="icon-border text-center" style="vertical-align: top;">
                    <img src="assets/img/Fix.svg"  height="100" alt="services-img">
                </div>
                <div class=""><br>
                    <h3 class="mb-3">Fix</h3>
                    <small class="fuente-pop-reg">· Spare parts management <br> · Repair <br> · (On-site support OPTIONAL)</small>
                </div>
            </div>
        </div>
    </div>
</div>

</div>
</div>
-->
