import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  public submitInquiry(data: any): Observable<any[]> {
    return this.http
      .post<any[]>(`/api/method/second_life.web.createContactForm`, data);
  }

  public getCountries(): Observable<any[]> {
    return this.http
      .get<any[]>(`/api/method/second_life.web.getCountries`);
  }

  public uploadSupportFile(form: any): Observable<any[]>{
    let b64 = form.supporting_documents.split(",");
    let data = b64[0].split(";");
    let fileType = data[0].split(":")[1];

    const byteCharacters = atob(b64[1]);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: fileType });
    const file = {
      file: blob,
      file_name: form.file_name,
      private: "1"
    };

    let form_data = new FormData();
    form_data.append('supporting_documents', file.file, file.file_name);
    form_data.append('doc_name', form.doc_name);
    console.log(form_data);

    return this.http.post<any[]>(`/api/method/second_life.web.uploadFileToFormWeb`, form_data);
  }

  public uploadNdaFile(form: any): Observable<any[]>{
    let b64 = form.nda.split(",");
    let data = b64[0].split(";");
    let fileType = data[0].split(":")[1];

    const byteCharacters = atob(b64[1]);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: fileType });
    const file = {
      file: blob,
      file_name: form.file_name,
      private: "1"
    };

    let form_data = new FormData();
    form_data.append('nda', file.file, file.file_name);
    form_data.append('doc_name', form.doc_name);

    console.log(form_data);

    return this.http.post<any[]>(`/api/method/second_life.web.uploadFileToFormWeb`, form_data);
  }

  public uploadFile(form: any): Observable<any[]>{
    let b64 = form.end.split(",");
    let data = b64[0].split(";");
    let fileType = data[0].split(":")[1];

    const byteCharacters = atob(b64[1]);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: fileType });
    const file = {
      file: blob,
      file_name: form.file_name,
      private: "1"
    };

    let form_data = new FormData();
    form_data.append('end', file.file, file.file_name);
    form_data.append('doc_name', form.doc_name);

    console.log(form_data);

    return this.http.post<any[]>(`/api/method/second_life.web.uploadFileToFormWeb`, form_data);
  }
}
