import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TrainningsService {


  constructor(private http: HttpClient) { }


  public getEventosWeb(datosEvent:any): Observable<any[]> {
    let datos = {
      pais: datosEvent.pais,
      ciudad: datosEvent.ciudad,
      fecha: datosEvent.fecha,
      url: datosEvent.url_evento,
      idioma: datosEvent.idioma
    }
    return this.http
      .post<any[]>(`/api/method/atr_marketing.solicitud.getEventosWeb`, datos);
  }

  
}
