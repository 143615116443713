
<div class="main-banner-contact">
  <div class="d-table">
      <div class="d-table-cell">
          <div class="container">
              <div class="main-banner-text">
                  <h1 class="pc-font-size mobile-font-size">We’re here to help and advise</h1><br>
                  <br><p class="fuente-pop-reg">If you need a general contact or technical support…<p><br>
                  <div class="row mt-3 flex justify-content-center">
                  <div class="col-lg-3 col-md-6 justify-context-right">
                      <p style="color:#EFE6C0;" class="fuente-pop-reg">General contact</p>
                    <div class="area-mail">
                      <a class="mail" href="mailto:contact@collective-energy.africa">
                        contact@collective-energy.africa
                    </a>
                  </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                    <p style="color:#EFE6C0;" class="fuente-pop-reg">Technical support</p>
                    <div class="area-mail">
                      <a class="mail" href="mailto:service@collective-energy.africa">
                        service@collective-energy.africa
                    </a>
                  </div>
                       <div>
                        <p style="color:#3A9B43; font-size:large" (click)="copyText('+254 712 585 351')">+254 712 585 351</p><br>
                       </div>

                </div>
                
                    
              </div>
              <div>
                <h5 class="texto-blanco">Or contact one of our subsidiaries directly…</h5>

              </div>
              <div class="d-flex flex-row justify-content-evenly mt-5 gap-3 p-2"> 
                <div class="col-3">
                  <h6 class="texto-verde text-start">KENYA</h6>
                  <p class="texto-blanco text-start justificar ">
                    6th floor Morningside Office Park, <br>
                    Kilimani, Nairobi, Kenya <br>
                    +254 712 988 211 <br>
                    vincent@collective-energy.africa
                  </p>
                </div>
                <div class="col-3">
                  <h6 class="texto-verde text-start">UGANDA</h6>
                  <p class="texto-blanco text-start justificar">
                    Nakasero, Central Division,<br>
                    Kampala, Uganda <br>
                    +256 756 571 836 <br>
                    henry@collective-energy.africa
                  </p>
                </div>
                <div class="col-3">
                  <h6 class="texto-verde text-start">SOUTH AFRICA </h6>
                  <p class="texto-blanco text-start justificar">
                    15 Ivor Street, Gillview,<br>
                    Johannesburg, South Africa <br>
                    +27 661 583 276 <br>
                    hugo@collective-energy.africa
                  </p>
                </div>
              </div>
              </div>
          </div>
      </div>
  </div>
</div>
<div class="main-banner main-banner-cont">
  <div class="d-table">
      <div class="d-table-cell">
          <div class="container">
              <div >
                  <div class="card-body" style="padding-top: 20px; padding-bottom: 20px;">
                      <div class="row align-items-center">
                          <div class="col-lg-12 col-md-12">
                              <div class="card-contacts" style="opacity: 0.9;">

                              <form [formGroup]="contactForm" (ngSubmit)="submit()" *ngIf="formularioEnviado == false" class="container" style="padding:5em;">
                                  <h1 class="form-title">Can we help you?</h1>
                                  <div class="row form-group">
                                      <div class="col-lg-6">
                                          <label for="first-name"></label>
                                          <input minlength="3" maxlength="50" type="text" formControlName="first_name" placeholder="First Name" required>
                                          <div *ngIf="contactForm.controls['first_name'].invalid && (contactForm.controls['first_name'].dirty || contactForm.controls['first_name'].touched || isSubmitted)" class="alert">
                                            <small class="text-danger" *ngIf="contactForm.controls['first_name'].errors.required">
                                            Please enter Your first name
                                            </small>
                                        </div>  
                                      </div>
                                      <div class="col-lg-6">
                                          <label for="surname"></label>
                                          <input minlength="3" maxlength="50" type="text" formControlName="surname" placeholder="Surname" required>
                                          <div *ngIf="contactForm.controls['surname'].invalid && (contactForm.controls['surname'].dirty || contactForm.controls['surname'].touched || isSubmitted)" class="alert">
                                            <small class="text-danger" *ngIf="contactForm.controls['surname'].errors.required">
                                            Please enter Your surname
                                            </small>
                                        </div> 
                                      </div>
                                  </div>

                                  <div class="row">
                                      <div class="col-lg-6">
                                          <label for="company-name"></label>
                                          <input type="text" formControlName="company_name" placeholder="Company Name" required>
                                          <div *ngIf="contactForm.controls['company_name'].invalid && (contactForm.controls['company_name'].dirty || contactForm.controls['company_name'].touched || isSubmitted)" class="alert">
                                            <small class="text-danger" *ngIf="contactForm.controls['company_name'].errors.required">
                                            Please enter Your company name
                                            </small>
                                        </div> 
                                      </div>
                                      <div class="col-lg-6">
                                          <label for="email"></label>
                                          <input type="email" formControlName="email" placeholder="Email" required>
                                          <div *ngIf="contactForm.controls['email'].invalid && (contactForm.controls['email'].dirty || contactForm.controls['email'].touched || isSubmitted)" class="alert">
                                            <small class="text-danger" *ngIf="contactForm.controls['email'].errors.required">
                                            Please enter Your email
                                            </small>
                                            <small class="text-danger" *ngIf="contactForm.controls['email'].errors.email">
                                                Please enter a valid Email
                                                </small>
                                        </div> 
                                      </div>
                                  </div>

                                  <div class="row form-group">
                                      <div class="col-lg-12">
                                          <label for="message"></label>
                                          <textarea rows="12" formControlName="message" placeholder="Type your message here"></textarea>
                                          <div *ngIf="contactForm.controls['message'].invalid && (contactForm.controls['message'].dirty || contactForm.controls['message'].touched || isSubmitted)" class="alert">
                                            <small class="text-danger" *ngIf="contactForm.controls['message'].errors.required">
                                            Please enter Type your message
                                            </small>
                                        </div> 
                                      </div>
                                  </div>

                                  <div class="row justify-content-end "> 
                                      <div class="col-lg-12 col-md-12 text-right"> 
                                          <button type="submit" style="height: 2rem;padding-left:3em; padding-right:3em;" class="btn boton btn-right">SEND →</button>
                                      </div>
                                  </div>
                              </form>
                              <div class="row" *ngIf="formularioEnviado == true">
                                  <div class="col-lg-12 col-md-6">
                                      <div class="form-group mb-3 mt-3 text-center ">
                                          <p>Your message has been send successfully.</p>
                                          </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  </div>
                  </div>
                  
              </div>
          </div>
      </div>
</div>
<div class="main-banner-home ">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <h1 class="pc-font-size-mt mobile-font-size">Find our distributors!</h1>
                    <p style="color:#EFE6C0; font-size:large">Contact us for more information</p><br>

                </div>
            </div>
        </div>
    </div>
</div>
<div class=" bg-fffdfd  pt-100 pb-70" id="welcome-raion">
    <div class="container">
        <div class="section">
    <br>
    <div class="row justify-content-center">
        <div class="col-lg-12 col-md-6 ">
          <div id="map" class="map" style="height: 500px;"></div>
          
          <script
      src="https://maps.googleapis.com/maps/api/js?key=AIzaSyClpMFjMX_UTHmMTepnY0V_oI2_xjhQX-s&callback=initMap&v=weekly"
      defer>
    </script>
        </div>
      </div>
      <!-- <div class="parrafos">
        <div class="col-lg-6 welcome-title1">
            <h1>Do you want to be our distributor?</h1>
        </div>
        <div class="col-lg-6 welcome-title2">
        </div>
    </div>
    <form [formGroup]="contactForm" (ngSubmit)="submit()" *ngIf="formularioEnviado == false">
    <div class="form-container">
        <div class="col-6">
            <div class="form-group">
              <label for="first-name"></label>
              <input minlength="3" maxlength="50" type="text" formControlName="first_name" placeholder="First Name" required>
              <div *ngIf="contactForm.controls['first_name'].invalid && (contactForm.controls['first_name'].dirty || contactForm.controls['first_name'].touched || isSubmitted)" class="alert">
                <small class="text-danger" *ngIf="contactForm.controls['first_name'].errors.required">
                Please enter Your first name
                </small>
            </div>  
            </div>
            <div class="form-group">
              <label for="surname"></label>
              <input minlength="3" maxlength="50" type="text" formControlName="surname" placeholder="Surname"required>
              <div *ngIf="contactForm.controls['surname'].invalid && (contactForm.controls['surname'].dirty || contactForm.controls['surname'].touched || isSubmitted)" class="alert">
                <small class="text-danger" *ngIf="contactForm.controls['surname'].errors.required">
                Please enter Your surname
                </small>
            </div> 
            </div>
            <div class="form-group">
              <label for="company-name"></label>
              <input type="text" formControlName="company_name" placeholder="Company Name" required>
              <div *ngIf="contactForm.controls['company_name'].invalid && (contactForm.controls['company_name'].dirty || contactForm.controls['company_name'].touched || isSubmitted)" class="alert">
                <small class="text-danger" *ngIf="contactForm.controls['company_name'].errors.required">
                Please enter Your company name
                </small>
            </div> 
            </div>
            <div class="form-group">
              <label for="email"></label>
              <input type="email" formControlName="email" placeholder="Email"required>
              <div *ngIf="contactForm.controls['email'].invalid && (contactForm.controls['email'].dirty || contactForm.controls['email'].touched || isSubmitted)" class="alert">
                <small class="text-danger" *ngIf="contactForm.controls['email'].errors.required">
                Please enter Your email
                </small>
                <small class="text-danger" *ngIf="contactForm.controls['email'].errors.email">
                    Please enter a valid Email
                    </small>
            </div> 
            </div>
            <div class="form-group mt-5">
              <input type="submit" value="Send →" class="btn boton" style="height: 2rem;padding-left:3em; padding-right:3em">
            </div>
        </div>
        <div class="col-6">
          <div class="form-group ms-3">
            <label for="message"></label>
            <textarea rows="12" formControlName="message" placeholder="Type your message here"></textarea>
            <div *ngIf="contactForm.controls['message'].invalid && (contactForm.controls['message'].dirty || contactForm.controls['message'].touched || isSubmitted)" class="alert">
              <small class="text-danger" *ngIf="contactForm.controls['message'].errors.required">
              Please enter Type your message
              </small>
          </div> 
          </div>
        </div>
      </div>
    </form>
    <div class="row" *ngIf="formularioEnviado == true">
      <div class="col-lg-12 col-md-6">
          <div class="form-group mb-3 text-center">
              <p>Your message has been send successfully.</p>
              </div>
      </div>
  </div> -->
    </div>
</div>

</div>
