import { Component, OnInit, HostListener } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { TrainningsService } from 'src/app/services/trainnings.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface Evento {
  fecha: string;
  pais: string;
  ciudad: string;
  url_evento: string;
  nombre: string;
}

@Component({
  selector: 'app-servicio',
  templateUrl: './servicio.component.html',
  styleUrls: ['./servicio.component.scss']
})
export class ServicioComponent {
  datosFiltrados: any[] = [];
  public datos: Evento[] = [];
  isLoading: boolean = false;
  faSpinner = faSpinner;
  isNavFixed: boolean = false;
  passedPosition: boolean = false;
  isSpanishLang: boolean;
  mostrar: boolean;
  isEnglishLang: boolean;
  cambioIdioma: string = '';
  targetPosition: number = 0; 

  constructor(
    private viewportScroller: ViewportScroller,
    private trainings: TrainningsService
  ) {
    this.datosFiltrados = [];
  }
  public onClick(elementId: string): void {
    const yOffset = this.isNavFixed ? -90 : -80;
    const element = document.getElementById(elementId);

    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      this.viewportScroller.scrollToPosition([0, elementPosition + yOffset]);
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (this.targetPosition === 0) {
      const targetElement = document.getElementById('mi-elemento'); 
      if (targetElement) {
        this.targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
      }
    }

    const scrollThreshold = 200;


    const shouldNavBeFixed = scrollPosition >= this.targetPosition;
    if (this.isNavFixed !== shouldNavBeFixed) {
      this.isNavFixed = shouldNavBeFixed;
    }

    const scrollToTopButton = document.querySelector('.scroll-to-top');
    if (scrollToTopButton) {
      if (scrollPosition > scrollThreshold) {
        scrollToTopButton.classList.remove('hidden');
      } else {
        scrollToTopButton.classList.add('hidden');
      }
    }
  }
  
  copyText(textToCopy: string) {
    const tempInput = document.createElement('input');
    tempInput.setAttribute('value', textToCopy);
    document.body.appendChild(tempInput);
    
    tempInput.select();
    document.execCommand('copy');
    
    document.body.removeChild(tempInput);
    
    alert('Texto copiado: ' + textToCopy);
  }
  

  ngOnInit() {
    this.getEventosWeb(); 
  }

  getEventosWeb() {
    this.isLoading = true;

    this.trainings.getEventosWeb(this.datos).subscribe({
      next: (r: any) => {

        const nuevosDatos = r.message || [];
        this.datosFiltrados = nuevosDatos;

        console.log(nuevosDatos);
        this.isLoading = false;



      },
      error: (error: any) => {
        console.error('An error occurred while fetching events:', error);
      }
    });
  }

}
