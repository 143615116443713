import { Component } from '@angular/core';
import {FormBuilder, Validators, FormsModule, ReactiveFormsModule, FormGroup} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { ContactService } from '../../services/contact.service';
import { ConfirmFormComponent } from '../confirm-form/confirm-form.component';

@Component({
  selector: 'app-customer-interested',
  templateUrl: './customer-interested.component.html',
  styleUrls: ['./customer-interested.component.scss']
})
export class CustomerInterestedComponent {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  public countries: any[] = []
  showInstalledPower: boolean = false;
  public fileSelected: any;
  public fileSelectedName: any;
  
  constructor(private _formBuilder: FormBuilder,private dialog: MatDialog, public contactService: ContactService) {
    this.firstFormGroup = this._formBuilder.group({
      address: ['', Validators.required],
      zip_code: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      average_energy_consumption: ['', Validators.required],
      desired_capacity: ['', Validators.required],
      installation_timeline: ['8 months'],
      have_energy_source: ['No'],
      installed_power: [''],
      coupling_type: ['AC coupling'],
      primary_application: [''],
      on_grid_off_grid: ['On-grid'],
      energy_consumption: [0.2],
      allowed_import: [''],
      allowed_export: [''],
      end:[''],
      comment:['']

    });
    this.thirdFormGroup = this._formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', Validators.required],
      phone_number: ['']
    });

  }

  ngOnInit(): void {
    this.contactService.getCountries().subscribe({
      next: (r: any) => {
        this.countries = r.message
      }
    })
  }

  onEnergySourceChange(value: string) {
    this.showInstalledPower = value === 'Yes';
  }

  fileSelectionChanged(event: any) {
    const [file] = event.target.files;
    const fileListAsArray = Array.from(event.target.files);
    fileListAsArray.forEach((item: any, i) => {
      this.fileSelected = item;
      this.fileSelectedName = item.name;
    });

    this.secondFormGroup.patchValue({
      file: this.fileSelectedName,
    });
  }


  submit(){
    if(this.thirdFormGroup.invalid) {
      return;
    }

    let data = Object.assign( this.firstFormGroup.value , this.secondFormGroup.value , this.thirdFormGroup.value, {"tipo": "End Customer"});
    console.log(data);

    this.contactService.submitInquiry(data).subscribe({
      next: (r: any) => {
        if(this.fileSelected) {
          let reader = new FileReader();
          reader.readAsDataURL(this.fileSelected);
          reader.onload = () => {
            let file = {
              "doc_name": r.message.name,
              "end": String(reader.result),
              "file_name": this.fileSelectedName
            }

            console.log(file);
            this.contactService.uploadFile(file).subscribe({
              next: (r: any) => {
                
              },
            })
          }
        }
        this.dialog.open(ConfirmFormComponent, { disableClose: true });
      },
      error: (e) => {
        console.log(e);
      }
    })

  }


}

