<div class="main-banner-contact pt-70">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <h1 class="pc-font-size mobile-font-size">We’re here to help and advise</h1>
                    <div class="row mt-3">
                    <div class="col-lg-7 col-md-6 justify-context-right">
                        <p style="color:#EFE6C0;" class="fuente-pop-reg">Contact</p>
                        <button type="button" class="btn btn-second " style="padding-left: 2em;padding-right:2em">
                            <a href="mailto:sales@collective-energy.africa">sales@collective-energy.africa</a>
                        </button>
                        <p style="color:#3A9B43; font-size:large" (click)="copyText('+254 712 988 211')">+254 712 988 211</p><br>

                    </div>
                    <div class="col-lg-3 col-md-6">
                        <p style="color:#EFE6C0;" class="fuente-pop-reg">Technical support</p>
                        <button type="button" class="btn btn-second"style="padding-left: 2em;padding-right:2em">
                            <a href="mailto:service@collective-energy.africa">
                                service@collective-energy.africa
                            </a></button>
                        <p style="color:#3A9B43; font-size:large" (click)="copyText('+254 207 640 823')">+254 207 640 823</p><br>

                      </div>
                      
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="main-banner main-banner-cont">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div >
                    <div class="card-body" style="padding-top: 20px; padding-bottom: 20px;">
                        <div class="row align-items-center">
                            <div class="col-lg-12 col-md-12">
                                <div class="card-contacts" style="opacity: 0.9;">

                                <form [formGroup]="contactForm" (ngSubmit)="submit()" *ngIf="formularioEnviado == false" class="container" style="padding:5em;">
                                    <h1 class="form-title">Can we help you?</h1>
                                    <div class="row form-group">
                                        <div class="col-lg-6">
                                            <label for="first-name"></label>
                                            <input minlength="3" maxlength="50" type="text" formControlName="first_name" placeholder="First Name" required>
                                            <div *ngIf="contactForm.controls['first_name'].invalid && (contactForm.controls['first_name'].dirty || contactForm.controls['first_name'].touched || isSubmitted)" class="alert">
                                              <small class="text-danger" *ngIf="contactForm.controls['first_name'].errors.required">
                                              Please enter Your first name
                                              </small>
                                          </div>  
                                        </div>
                                        <div class="col-lg-6">
                                            <label for="surname"></label>
                                            <input minlength="3" maxlength="50" type="text" formControlName="surname" placeholder="Surname" required>
                                            <div *ngIf="contactForm.controls['surname'].invalid && (contactForm.controls['surname'].dirty || contactForm.controls['surname'].touched || isSubmitted)" class="alert">
                                              <small class="text-danger" *ngIf="contactForm.controls['surname'].errors.required">
                                              Please enter Your surname
                                              </small>
                                          </div> 
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-lg-6">
                                            <label for="company-name"></label>
                                            <input type="text" formControlName="company_name" placeholder="Company Name" required>
                                            <div *ngIf="contactForm.controls['company_name'].invalid && (contactForm.controls['company_name'].dirty || contactForm.controls['company_name'].touched || isSubmitted)" class="alert">
                                              <small class="text-danger" *ngIf="contactForm.controls['company_name'].errors.required">
                                              Please enter Your company name
                                              </small>
                                          </div> 
                                        </div>
                                        <div class="col-lg-6">
                                            <label for="email"></label>
                                            <input type="email" formControlName="email" placeholder="Email" required>
                                            <div *ngIf="contactForm.controls['email'].invalid && (contactForm.controls['email'].dirty || contactForm.controls['email'].touched || isSubmitted)" class="alert">
                                              <small class="text-danger" *ngIf="contactForm.controls['email'].errors.required">
                                              Please enter Your email
                                              </small>
                                              <small class="text-danger" *ngIf="contactForm.controls['email'].errors.email">
                                                  Please enter a valid Email
                                                  </small>
                                          </div> 
                                        </div>
                                    </div>

                                    <div class="row form-group">
                                        <div class="col-lg-12">
                                            <label for="message"></label>
                                            <textarea rows="12" formControlName="message" placeholder="Type your message here"></textarea>
                                            <div *ngIf="contactForm.controls['message'].invalid && (contactForm.controls['message'].dirty || contactForm.controls['message'].touched || isSubmitted)" class="alert">
                                              <small class="text-danger" *ngIf="contactForm.controls['message'].errors.required">
                                              Please enter Type your message
                                              </small>
                                          </div> 
                                        </div>
                                    </div>

                                    <div class="row justify-content-end "> 
                                        <div class="col-lg-12 col-md-12 text-right"> 
                                            <button type="submit" style="height: 2rem;padding-left:3em; padding-right:3em;" class="btn boton btn-right">SEND →</button>
                                        </div>
                                    </div>
                                </form>
                                <div class="row" *ngIf="formularioEnviado == true">
                                    <div class="col-lg-12 col-md-6">
                                        <div class="form-group mb-3 mt-3 text-center ">
                                            <p>Your message has been send successfully.</p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
                    
                </div>
            </div>
        </div>

<img src="/assets/img/Visit.png" alt="">