<div class="main-banner-home"> 
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-texts">
                    <h1 class=" mobile-font-size-sm ">Your Pan-African <br >Smart Energy Expert</h1>
                    <p >Providing reliable energy solutions, expert service, and training across Africa.</p><br><br><br>
                    <div class="col-lg-12 col-md-12 ">
                        <a routerLink="/about-us"><button type="submit" class="btn btn-second">LEARN MORE →</button></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="main-banners main-banner-imagen" >
    <div class="d-table hresp">
        <div class="d-table-cell">
            <div class="container">
                <div class="card-cea"style="opacity: 0.96; ">
                   <div class="card-body" >
                    <div class="row align-items-center ">
                      <div class="col-lg-5 col-md-12 col-sm-12">
                          <div class="about-content">
                              <div class="section-title">
                                  <h1 class="what-cea titulocea "style="text-align: left">What we do</h1><br>
                                  <p class="fuente line-cea">At Collective Energy Africa (CEA), our mission is to transform energy storage across Africa with top-tier BYD BatteryBox LFP products. We started in Kenya, where we quickly became the leading distributor of lithium-ion batteries in East Africa, and now we are bringing our expertise to the entire continent with subsidiaries in Kenya, Uganda and South Africa.</p>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-7 col-md-12 text-center">
                          <div class="about-video">
                              <img class="img-what" src="assets/img/LV FLEX LITE.png" alt="about">
                              <div class="video-btn">
                              </div>
                          </div>
                      </div>
                  </div>
                  
                   </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="bg-fffdfd ptb-100">
  <div class="container">
      <div class="section">
          <div class="">
              <div class="section">
                  <div class="battery-cea-homes bettery mb-5">
                      <span>WHY CEA?</span><br>
                  </div>
                  <div class="row row-cols-4 justify-content-center equal-height pb-100">


                      <div class="col-lg-3 col-md-6 col-6">
                        <div class="content-wrapper col">
                              <div class="services-content mb-2">
                                  <h3 class="borders-top">Quality you can trust, performance you can rely on.</h3>
                                  <span class="subtext">The synergy of BYD’s superior products and CEA’s exceptional service guarantees unmatched quality and reliability in every energy solution we provide.</span><br>
                              </div>
                              <div class="services-img">
                                  <img src="assets/img/LocalPresence.jpeg" alt="services-img">
                              </div>
                          </div>
                      </div>

                    
                      <div class="col-lg-3 col-md-6 col-6">
                        <div class="content-wrapper">
                              <div class="services-content  mb-2">
                                  <h3 class="borders-top">From East to West and beyond.</h3>
                                  <span class="subtext">As East Africa’s largest supplier of lithium-ion batteries, CEA is now expanding its expertise and innovative BYD solutions across the continent.</span><br>
                              </div>
                              <div class="services-img">
                                  <img src="assets/img/LocalWarehousing.jpg" alt="services-img">
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-3 col-md-6 col-6">
                        <div class="content-wrapper">
                              <div class="services-content  mb-2">
                                  <h3 class="borders-top">Empowering local talent, delivering global standards.</h3>
                                  <span class="subtext">Through comprehensive training programs and local customer service, CEA equips local technicians with the skills to deliver world-class BYD energy solutions.</span><br>
                              </div>
                              <div class="services-img">
                                  <img src="assets/img/LocalTraining.jpg" alt="services-img">
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-3 col-md-6 col-6">
                        <div class="content-wrapper">
                              <div class="services-content  mb-2">
                                  <h3 class="borders-top">Powering Africa with advanced energy solutions.</h3>
                                  <span class="subtext">CEA provides top-tier BYD lithium-ion batteries,<br> ensuring reliable and efficient energy storage across the continent.<br></span><br>
                              </div>
                              <div class="services-img">
                                  <img src="assets/img/LocalSupport.jpg" alt="services-img">
                              </div>
                          </div>
                      </div>

                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<div class=" bg-0B1F0D  pt-100 " >
    <div class="container">
        <div class="section">
          <div class="battery-cea-home text-center ">
            <span style="color:#EFE6C0; opacity:0.25" class="d-block">BYD</span>
            <span style="color:#EFE6C0; opacity:0.25" class="d-block">BATTERYBOX</span>
        </div>
    </div>
</div>
</div>
<div class="main-banner ">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="main-banner-texts">
            <h2 class="texto-blanco">The simplest and easiest energy <br> storage systems:</h2> <br>
          </div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-4">
                <div class="text-center"> 
                  <div class="services-img">
                    <img src="assets/img/expansion.svg" height="200" alt="services-img">
                  </div>
                  <div class=""><br>
                    <h3 class="texto-blanco" style="font-size: 1.3rem;">Simple layout</h3><br>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="text-center">
                  <div class="services-img" style="vertical-align: top;">
                    <img src="assets/img/layout.svg" height="200px" alt="services-img">
                  </div>
                  <div class=""><br>
                    <h3 class="texto-blanco" style="font-size: 1.3rem;">Simple expansion </h3><br>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="text-center"> 
                  <div class="services-img" style="vertical-align: top;">
                    <img src="assets/img/powerful.svg" height="200px" alt="services-img">
                  </div>
                  <div class=""><br>
                    <h3 class="texto-blanco" style="font-size: 1.3rem;">Simply powerful</h3><br>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
