<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
  <div class="d-flex burger">
    <div>
      <a class="navbar-brand" routerLink="/Home">
        <img src="/assets/img/CE_white.png" alt="Logo">
      </a>
      <button class="navbar-toggler" type="button" (click)="toggleClass()">
        <span class="burger-menu">
          <span class="top-bar"></span>
          <span class="middle-bar"></span>
          <span class="bottom-bar"></span>
        </span>
      </button>
    </div>
   
    <div class="collapse navbar-collapse "  id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto mr-2" >
        <li class="nav-item" [class.active]="currentSection === 'about-us'">
          <div >
            <a [routerLink]="['/about-us']" [queryParams]="{ section: 'about-us' }" class="link-btn texto-blanco texto-secciones punto " (click)="changeSection('section3'); closeMenu()">About us</a>
          </div>
        </li>
       
        <li class="nav-item" [class.active]="currentSection === 'byd-betterybox'">
          <div>
            <a [routerLink]="['/byd-betterybox']" [queryParams]="{ section: 'byd-betterybox' }" class="link-btn texto-blanco texto-secciones" (click)="changeSection('section3'); closeMenu()">Products</a>
          </div>
        </li>

        <li class="nav-item" [class.active]="currentSection === 'solutions'">
          <div>
            <a [routerLink]="['/solutions']" [queryParams]="{ section: 'solutions' }" class="link-btn texto-blanco texto-secciones" (click)="changeSection('section3'); closeMenu()">C&I Solutions</a>
          </div>
        </li>
        <li class="nav-item" [class.active]="currentSection === 'service'">
          <div>
            <a [routerLink]="['/service']" [queryParams]="{ section: 'service' }" class="link-btn texto-blanco texto-secciones" (click)="changeSection('section3'); closeMenu()">Service</a>
          </div>
        </li>
        <li class="nav-item" [class.active]="currentSection === 'charging'">
          <div>
            <a [routerLink]="['/charging']" [queryParams]="{ section: 'charging' }" class="link-btn texto-blanco texto-secciones" (click)="changeSection('section3'); closeMenu()">EV Charging</a>
          </div>
        </li>
        <li class="nav-item" [class.active]="currentSection === 'Where-to-buy'">
          <div>
            <a [routerLink]="['/Where-to-buy']" [queryParams]="{ section: 'Where-to-buy' }" class="link-btn texto-blanco texto-secciones" (click)="changeSection('section4'); closeMenu()">Contact</a>
          </div>
        </li>
        <!-- <li class="nav-item" [class.active]="currentSection === 'Contact'">
          <div>
            <a [routerLink]="['/Contact']" [queryParams]="{ section: 'Contact' }" class="link-btn texto-blanco texto-secciones" (click)="changeSection('section5'); closeMenu()"></a>
          </div>
        </li> -->
      </ul>
      <ul class="navbar-nav ml-auto pt-2">
        <li class="nav-item" [class.active]="currentSection === 'Online-service-center'">
          <div>
            <a href="https://support.collective-energy.africa/" target="_blank" class="link-btn texto-secciones online-margin" (click)="changeSection('section6'); closeMenu()" style="margin-right: 1em;">
              <span style="color:#3A9B43">Online service center</span>
            </a>
          </div>
        </li>
        <li class="nav-item text-right">
          <div>
            <button class="btn btn-primary" style="margin-top: 2.5em; margin-right: 1.5em;">
              <a href="http://docs.collective-energy.africa/"  (click)="closeMenu()">
                <span class="texto-blanco texto-secciones" style="border-bottom: transparent !important;">Downloads</span>
              </a>
            </button>
          </div>
        </li>
      </ul>
      <ul class="navbar-nav ml-left for-responsive">
        <li class="nav-item"><span class="nav-link" routerLink="/about-us" (click)="closeMenu()">About us</span></li>
        <li class="nav-item"><span class="nav-link" routerLink="/byd-betterybox" (click)="closeMenu()">Products</span></li>
        <li class="nav-item"><span class="nav-link" routerLink="/solutions" (click)="closeMenu()">C&I Solutions</span></li>
        <li class="nav-item"><span class="nav-link" routerLink="/service" (click)="closeMenu()">Service</span></li>
        <li class="nav-item"><span class="nav-link" routerLink="/charging" (click)="closeMenu()">EV Charging</span></li>
        <li class="nav-item"><span class="nav-link" routerLink="/Where-to-buy" (click)="closeMenu()">Contact</span></li>
        <li class="nav-item">
          <a class="nav-link" href="https://support.collective-energy.africa/" (click)="closeMenu()">Online service center</a>
        </li>                
        <li class="nav-item"><span class="nav-link" style="color: #3A9B43;" href="http://docs.collective-energy.africa/" (click)="closeMenu()">DOWNLOADS</span></li>
      </ul>
    </div>
  </div>
</nav>
